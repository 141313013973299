export const templateLoopParser = (list, template) => {
  let parsedTemplate = "";
  list.forEach((item) => {
    const keys = Object.keys(item);
    let templateToProcess = template;
    keys.forEach((key) => {
      try {
        templateToProcess = templateToProcess.replace(`{{${key}}}`, `${item[key]}`);
      } catch (e) {
        console.error("Could not replace template", e);
      }
    });
    parsedTemplate += templateToProcess;
  });
  return `${parsedTemplate}`;
};
