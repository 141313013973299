import fs from "fs";
import template from "bundle-text:./index.html";
import { templateLoopParser } from "../parser";
import { API_URL } from "../constants";

const projectTemplate = fs.readFileSync(__dirname + "/template.html", "utf8");

export const Projects = () => {
  setTimeout(() => {
    fetchTemplates();
  }, 0);

  const fetchTemplates = () => {
    document.querySelector(".projects-loading").style.display = "flex";
    const projectsContainer = document.querySelector(".projects-container");
    projectsContainer.innerHTML = "";
    fetch(`${API_URL}/github/users/justgeek/repos`)
      .then((response) => response.json())
      .then((response) => {
        const filteredProjects = response.map((project) => {
          const { name, html_url, description, created_at, stargazers_count } = project;
          return { name, html_url, description, created_at, stargazers_count };
        });

        const projectsContent = `<div class="projects-list">${templateLoopParser(filteredProjects, projectTemplate)}</div>`;
        const projects = new DOMParser().parseFromString(projectsContent, "text/html");
        projects.querySelector(".projects-list").childNodes.forEach((child) => {
          projectsContainer.appendChild(child);
        });

        // remove loading
        document.querySelector(".projects-loading").style.display = "none";
      });
  };

  return template;
};
