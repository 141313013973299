export const skills = [
  {
    label: "ACTIONSCRIPT 3.0",
    imageUrl: "../../assets/skills/as3.svg",
  },
  {
    label: "JAVASCRIPT",
    imageUrl: "../../assets/skills/js.svg",
  },
  {
    label: "TYPESCRIPT",
    imageUrl: "../../assets/skills/ts.svg",
  },
  {
    label: "ANGULAR",
    imageUrl: "../../assets/skills/angular.svg",
  },
  {
    label: "REACTJS",
    imageUrl: "../../assets/skills/react.svg",
  },
  {
    label: "VUE",
    imageUrl: "../../assets/skills/vue.svg",
  },
  {
    label: "REACT NATIVE",
    imageUrl: "../../assets/skills/react.svg",
  },
  {
    label: "NATIVE SCRIPT",
    imageUrl: "../../assets/skills/ns.svg",
  },
  {
    label: "NODEJS",
    imageUrl: "../../assets/skills/nodejs.svg",
  },
  {
    label: "METEOR",
    imageUrl: "../../assets/skills/meteor.svg",
  },
  {
    label: "LOOPBACK",
    imageUrl: "../../assets/skills/lb.svg",
  },
  {
    label: "KOA",
    imageUrl: "../../assets/skills/koa.svg",
  },
  {
    label: "EXPRESSJS",
    imageUrl: "../../assets/skills/express.svg",
  },
  {
    label: "AWS*",
    imageUrl: "../../assets/skills/aws.svg",
  },
  {
    label: "GCLOUD*",
    imageUrl: "../../assets/skills/gcloud.svg",
  },
  {
    label: "AZURE*",
    imageUrl: "../../assets/skills/azure.svg",
  },
  {
    label: "TERRAFORM",
    imageUrl: "../../assets/skills/tf.svg",
  },
  {
    label: "ANSIBLE",
    imageUrl: "../../assets/skills/ansible.svg",
  },
  {
    label: "PULUMI",
    imageUrl: "../../assets/skills/pulumi.svg",
  },
  {
    label: "Figma",
    imageUrl: "../../assets/skills/figma.svg",
  },
  {
    label: "ILLUSTRATOR",
    imageUrl: "../../assets/skills/ai.svg",
  },
  {
    label: "FIREWORKS",
    imageUrl: "../../assets/skills/fw.svg",
  },
  {
    label: "FLASH",
    imageUrl: "../../assets/skills/fl.svg",
  },
  {
    label: "PHOTOSHOP",
    imageUrl: "../../assets/skills/ps.svg",
  },
];
