import fs from "fs";
import template from "bundle-text:./index.html";
import { skills as skillList } from "./skills";
import { templateLoopParser } from "../parser";

const skillListTemplate = fs.readFileSync(__dirname + "/template.html", "utf8");

export const Skills = () => {
  setTimeout(() => {
    ("use strict");
    // Inject skills
    const skillContainer = document.querySelector(".skill-container");
    const skillContent = `<div class="skill-list">${templateLoopParser(skillList, skillListTemplate)}</div>`;
    var skills = new DOMParser().parseFromString(skillContent, "text/html");
    skills.querySelector(".skill-list").childNodes.forEach((child) => {
      skillContainer.appendChild(child);
    });
  }, 0);
  return template;
};
