import template from "bundle-text:./index.html";

export const Contact = () => {
  setTimeout(() => {
    const CANVAS_WIDTH = 900;
    const CANVAS_HEIGHT = 300;
    const FRAME_TIME = 1000 / 16;
    const LIGHT_ROWS = 20;
    const LIGHT_ROW_DEPTH = 2;
    const LIGHT_SPACING = 0.6;
    const LIGHT_SIZE = 0.1;
    const LIGHT_SCATTER = 0.4;
    const BUILDING_ROWS = 38;
    const BUILDING_ROW_DEPTH = 1;
    const BUILDING_ROW_WIDTH = 60;
    const BUILDING_MIN_HEIGHT = 1.5;
    const BUILDING_MAX_HEIGHT = 3;
    const STACK_HEIGHT = 9;
    const STACK_THRESHOLD = 0.87;
    const STACK_LIGHT_CHANCE = 0.95;
    const STACK_LIGHT_SIZE = 0.13;
    const FADE_GRAY_VALUE = 25;
    const FADE_OFFSET = 0.35;

    const CAMERA = {
      x: 0,
      y: 10,
      z: 0,
      fov: 170,
      dist: 30,
      zSpeed: 0.005,
    };

    const VP_OFS = {
      x: 0.5,
      y: 0.27,
    };

    let c, ctx, output_c, output_ctx;
    let _t, _dt, _ft;

    const RNG = {
      seed: 1,
      random() {
        const x = Math.sin(RNG.seed++) * 10000;
        return x - (x << 0);
      },
      randomInRange(min, max) {
        return ((RNG.random() * (max - min + 1)) << 0) + min;
      },
    };

    const Palette = (() => {
      const PAL = ["black", "#111", "#113", "white", "sliver", "#f88", "orange", "oldlace", "#569"];
      const lastIndex = PAL.length - 1;

      function getRandomFromPalette() {
        return PAL[RNG.randomInRange(0, lastIndex)];
      }

      return {
        getRandom: getRandomFromPalette,
      };
    })();

    function ceil(n) {
      var f = n << 0,
        f = f == n ? f : f + 1;
      return f;
    }

    function update() {
      _t = Date.now() * 0.001;
      CAMERA.z += CAMERA.zSpeed;
    }

    let _$ = {
      vPointX: 0,
      vPointY: 0,
      rowScreenX: 0,
      MAX_LIGHTS: 0,
      closestLightRow: 0,
      rowZ: 0,
      rowRelativeZ: 0,
      scalingFactor: 0,
      rowScreenWidth: 0,
      rowScreenHeight: 0,
      rowScreenY: 0,
      rowScreenLightSpacing: 0,
      rowLightCount: 0,
      lightSize: 0,
      lightHalfSize: 0,
      lightScreenX: 0,
      lightScreenY: 0,
      closestBuildingRow: 0,
      rowBuildingCount: 0,
      rowBuildingScreenWidth: 0,
      rowShade: 0,
      rowStyleString: "",
      lightData: [],
      isStack: false,
      buildingHeight: 0,
      buildingScreenHeight: 0,
      buildingScreenX: 0,
      buildingScreenY: 0,
      lightSize: 0,
      lightHalfSize: 0,
      lightColor: 0,
    };
    function render() {
      _$.vPointX = (c.width * VP_OFS.x) >> 0;
      _$.vPointY = (c.height * VP_OFS.y) >> 0;

      _$.rowScreenX = CAMERA.x + _$.vPointX;

      ctx.clearRect(0, 0, c.width, c.height);
      output_ctx.clearRect(0, 0, output_c.width, output_c.height);

      _$.closestLightRow = Math.floor(CAMERA.z / LIGHT_ROW_DEPTH);

      for (let i = 0; i < LIGHT_ROWS; i++) {
        _$.rowZ = _$.closestLightRow * LIGHT_ROW_DEPTH + LIGHT_ROW_DEPTH * i;
        _$.rowRelativeZ = _$.rowZ - CAMERA.z;

        if (_$.rowRelativeZ <= 0 || _$.rowRelativeZ > CAMERA.dist) {
          continue;
        }

        _$.scalingFactor = CAMERA.fov / _$.rowRelativeZ;
        _$.rowScreenY = CAMERA.y * _$.scalingFactor + _$.vPointY;

        if (_$.rowScreenY > c.height) {
          continue;
        }

        _$.rowScreenLightSpacing = LIGHT_SPACING * _$.scalingFactor;
        _$.rowLightCount = c.width / _$.rowScreenLightSpacing;

        RNG.seed = _$.rowZ * 0.573;

        for (let j = 0; j < _$.rowLightCount; j++) {
          _$.lightSize = RNG.random() * (LIGHT_SIZE * _$.scalingFactor);
          _$.lightHalfSize = _$.lightSize * 0.5;

          _$.lightScreenX = j * _$.rowScreenLightSpacing + RNG.random() * LIGHT_SCATTER * _$.scalingFactor - _$.lightHalfSize;
          _$.lightScreenY = _$.rowScreenY + RNG.random() * LIGHT_SCATTER * _$.scalingFactor - _$.lightHalfSize;

          if (_$.lightScreenX < 0 || _$.lightScreenX > c.width || _$.lightScreenY > c.height) {
            Palette.getRandom();
            continue;
          }

          ctx.fillStyle = Palette.getRandom();

          ctx.fillRect(_$.rowScreenX + _$.lightScreenX, _$.lightScreenY, _$.lightSize, _$.lightSize);
          ctx.fillRect(_$.rowScreenX - _$.lightScreenX, _$.lightScreenY, _$.lightSize, _$.lightSize);
        }
      }

      _$.closestBuildingRow = Math.floor(CAMERA.z / BUILDING_ROW_DEPTH);

      for (let i = BUILDING_ROWS; i > 0; i--) {
        _$.rowZ = _$.closestBuildingRow * BUILDING_ROW_DEPTH + BUILDING_ROW_DEPTH * i;
        _$.rowRelativeZ = _$.rowZ - CAMERA.z;

        if (_$.rowRelativeZ <= 0 || _$.rowRelativeZ > CAMERA.dist) {
          continue;
        }

        _$.scalingFactor = CAMERA.fov / _$.rowRelativeZ;

        // Calculate the perspective-scaled position and base size of our row.
        // Offset the XY so that the row's 'origin' is at centre bottom (i.e. ground-up)
        _$.rowScreenWidth = BUILDING_ROW_WIDTH * _$.scalingFactor;
        _$.rowScreenHeight = BUILDING_MAX_HEIGHT * _$.scalingFactor;
        _$.rowScreenX = CAMERA.x * _$.scalingFactor + _$.vPointX - _$.rowScreenWidth * 0.5;
        _$.rowScreenY = CAMERA.y * _$.scalingFactor + _$.vPointY - _$.rowScreenHeight;

        // Seed the RNG to keep rendering consistent for this row
        RNG.seed = _$.rowZ;

        // Calculate a random number of buildings for this row
        // and get their screen width
        _$.rowBuildingCount = RNG.randomInRange(20, 70);
        _$.rowBuildingScreenWidth = _$.rowScreenWidth / _$.rowBuildingCount;

        // Calculate the shade we want the buildings in this row to be.
        // The tint is darker nearer the camera, giving a sort of crude distance fog
        // near the horizon.
        _$.rowShade = Math.round(FADE_GRAY_VALUE * (_$.rowRelativeZ / CAMERA.dist - FADE_OFFSET));
        _$.rowStyleString = "rgb(" + _$.rowShade + "," + _$.rowShade + "," + _$.rowShade + ")";

        // Calclate and render each building
        _$.lightData.length = 0;
        ctx.fillStyle = _$.rowStyleString;
        for (let j = 0; j < _$.rowBuildingCount; j++) {
          // Buildings have a certain chance to become a 'stack' i.e. way taller than
          // everything else. We calculate a random ranged height for the building,
          // and if it exceeds a threshold, it gets turned into a stack.
          _$.isStack = false;
          _$.buildingHeight = Math.max(BUILDING_MIN_HEIGHT, RNG.random() * BUILDING_MAX_HEIGHT);

          if (_$.buildingHeight > BUILDING_MAX_HEIGHT * STACK_THRESHOLD) {
            _$.isStack = true;
            // Stacks have 40% height variance
            _$.buildingHeight = STACK_HEIGHT * 0.6 + RNG.random() * 0.4;
          }

          // Calculate the pixel size and position of this building, adjusted for perspective
          _$.buildingScreenHeight = _$.buildingHeight * _$.scalingFactor;
          _$.buildingScreenX = _$.rowScreenX + j * _$.rowBuildingScreenWidth;
          _$.buildingScreenY = _$.rowScreenY + _$.rowScreenHeight - _$.buildingScreenHeight;

          // Draw the building on screen
          ctx.fillRect(_$.buildingScreenX, _$.buildingScreenY, Math.ceil(_$.rowBuildingScreenWidth), _$.buildingScreenHeight);

          // Seed the RNG for consistency when calculating stack lights (if needed)
          RNG.seed = _$.buildingHeight + j;

          // Stacks have a chance to get lights on their top corners.
          // Generate and store light data so we can render it on top of the buildings
          if (_$.isStack && RNG.random() < STACK_LIGHT_CHANCE) {
            // Get random light size and color.
            // Slightly higher chance of red vs white lights
            _$.lightSize = RNG.random() * (STACK_LIGHT_SIZE * _$.scalingFactor);
            _$.lightColor = RNG.random() > 0.6 ? "white" : "#00c3ff";
            // Save light info for rendering after we do all the buildings
            // (helps minimixe changes to ctx.fillStyle)
            _$.lightData.push(_$.buildingScreenX);
            _$.lightData.push(_$.buildingScreenY);
            _$.lightData.push(_$.lightSize);
            _$.lightData.push(_$.lightColor);
          }
        }

        // Draw any lights on stacks that need them in this row
        for (let j = 0; j < _$.lightData.length; j += 4) {
          _$.buildingScreenX = _$.lightData[j];
          _$.buildingScreenY = _$.lightData[j + 1];
          _$.lightSize = _$.lightData[j + 2];
          _$.lightHalfSize = _$.lightSize * 0.5;
          _$.lightColor = _$.lightData[j + 3];

          // Draw lights centred at the top left and right corners of the stack
          ctx.fillStyle = _$.lightColor;
          ctx.fillRect(_$.buildingScreenX - _$.lightHalfSize, _$.buildingScreenY - _$.lightHalfSize, _$.lightSize, _$.lightSize);
          ctx.fillRect(
            _$.buildingScreenX + _$.rowBuildingScreenWidth - _$.lightHalfSize,
            _$.buildingScreenY - _$.lightHalfSize,
            _$.lightSize,
            _$.lightSize,
          );
        }
      }

      output_ctx.drawImage(c, 0, 0);
    }

    function frame() {
      requestAnimationFrame(frame);
      _ft = Date.now();
      update();
      if (_ft - _dt > FRAME_TIME) {
        render();
        _dt = _ft;
      }
    }

    function start() {
      _dt = _ft = Date.now();

      c = document.createElement("canvas");
      ctx = c.getContext("2d");

      output_c = document.createElement("canvas");
      output_ctx = output_c.getContext("2d");

      output_c.width = c.width = CANVAS_WIDTH;
      output_c.height = c.height = CANVAS_HEIGHT;
      const contactContainer = document.querySelector("#contact");
      contactContainer.appendChild(output_c);

      // Start the main loop.
      frame();
    }

    start();
    hbspt.forms.create({
      region: "eu1",
      portalId: "26760381",
      formId: "fb38dfa9-70af-4dd6-bbf0-b0b746f57375",
      target: "#contact-form",
    });

    // Select the node that will be observed for mutations
    const contactNode = document.querySelector("#contact");

    // Options for the observer (which mutations to observe)
    const config = { attributes: true, childList: true, subtree: true };

    // Callback function to execute when mutations are observed
    const callback = (mutationList, observer) => {
      for (const mutation of mutationList) {
        if (mutation.addedNodes.length) {
          setTimeout(() => {
            const iframe = document.querySelector(".hs-form-iframe");
            const doc = iframe.contentDocument;
            const innerObserver = new MutationObserver(() => {
              const link = doc.querySelector(".hubspot-link__container");
              if (link) {
                link.style.display = "none";

                doc.head.innerHTML =
                  doc.head.innerHTML +
                  `<style>
                  input[type=email],input[type=text],textarea{
                    background:transparent !important;
                    color: white !important;
                   }
                  textarea{
                    resize: none;
                  }
                  .submitted-message{
                    color: #00c3ff;
                  }
                </style>`;
                innerObserver.disconnect();
                observer.disconnect();
              }
            });
            innerObserver.observe(doc, { subtree: true, childList: true });
          }, 0);
        }
      }
    };

    // Create an observer instance linked to the callback function
    const observer = new MutationObserver(callback);

    // Start observing the target node for configured mutations
    observer.observe(contactNode, config);
  }, 0);

  return template;
};

export default Contact;
