export const careerHistory = [
  {
    name: "Bytro",
    logoUrl: "assets/career/bytro-logo.png",
    startDate: "SEP 2021",
    endDate: "Present",
    position: "Engineering Manager",
    summary: `Bytro is a gaming studio based in Hamburg, specialized in building web strategy games, and a part of a
    big swedish holding group called Stillfront<br>
    <b>Responsibilities & Achievements:</b>
    ● Mentor and manage our Engineering team, and create growth plans for them<br>
    ● Manage hiring, and resource planning for our projects<br>
    ● Build framework to scaffold for future proof games<br>
    ● Build design system for our games<br>
    ● Execute long term plan to refactor our legacy code<br>
    ● Plan and Execute company OKRs<br>
    `,
  },
  // {
  //   name: "Vidmob",
  //   logoUrl: "assets/career/vidmob-logo.png",
  //   startDate: "DEC 2020",
  //   endDate: "SEP 2021",
  //   position: "Lead Engineer",
  //   summary: `VidMob is a video content creation network and platform where brands can create digital video ads with
  //   expert teams.<br>
  //   <b>Responsibilities & Achievements:</b>
  //   ● Build design system for our web applications<br>
  //   ● Technically lead engineering team while building our design system<br>
  //   ● Handle communication, and planning with designers in design team<br>
  //   ● Assist our project manager with ticket creation, and task allocation<br>
  //   `,
  // },

  {
    name: "ShareNow",
    logoUrl: "assets/career/sn-logo.png",
    startDate: "NOV 2018",
    endDate: "DEC 2020",
    position: "Senior Web Engineer",
    summary: `Sharenow is a big venture of Daimler and BMW. Our goal is to push mobility towards sharing rather than
    buying<br>
    <b>Responsibilities & Achievements:</b>
    ● Develop B2B features required by our stakeholders<br />
    ● Fully handle B2B apps infrastructure<br />
    ● Build design system for our user web/mobile applications (Camelot)<br />
    ● Build design system for our internal applications/portals (Plattenbau)<br />
    ● Contribute to Architecture guild meetings, to decide on company high level Engineering topics<br />`,
  },
  {
    name: "About You",
    logoUrl: "assets/career/ay-logo.png",
    startDate: "FEB 2018",
    endDate: "OCT 2018",
    position: "Senior Mobile Engineer",
    summary: `ABOUT YOU is an eCommerce platform for clothing, shoes and accessories, and is one of the early
    Hamburg unicorns.<br>
    <b>Responsibilities & Achievements:</b>
    ● Optimize app performance and improve user experience<br>
    ● Add new features required by our stakeholders<br>
    ● Introduce and implement unit tests<br>
    ● Improve our CI/CD process (Bitrise)<br>
    ● Build server middleware in NodeJS to reduce dependency on PHP backend Engineers<br>`,
  },
  {
    name: "Baddel",
    logoUrl: "assets/career/baddel-logo.png",
    startDate: "MAY 2016",
    endDate: "FEB 2018",
    position: "Director of Technology",
    summary: `Baddel was one of the first micro mobility platforms in middle east to offer e-bike rental service using your
    smartphone<br>
    <b>Responsibilities & Achievements:</b>
    ● Help business to technically go from ideation phase to production phase<br>
    ● Hire and mentor Engineering team<br>
    ● Coordinate communication between our technical team and technical 3rd parties<br>
    ● Build & architect scalable infrastructure for our product<br>
    ● Managed customer service myself to understand our users bette<br>`,
  },
  {
    name: "Aphrie",
    logoUrl: "assets/career/aphrie-logo.png",
    startDate: "JAN 2016",
    endDate: "JAN 2018",
    position: "Co-Founder & CTO",
    summary: `Aphrie aims to be Siri for your car. I founded Aphrie in 2016, successfully graduated from AUC V-Lab
    accelerator, then Y- Combinator startup school. Aphrie was one of 300 startups to join YC startup school
    first batch<br>
    Our first product was a small gadget that introduces light weight version of our AI assistant:<br>
    more info at <a href='https://www.wheemo.com' target='blank'>https://www.wheemo.com</a>
    <br>
    <b>Responsibilities & Achievements:</b>
    ● Learned all it takes to found a company and manage business<br>
    ● Gathered a strong team and convinced potential co-founders to join<br>
    ● Built a robust business model<br>
    ● Pitched the idea to all potential investors<br>
    ● Successfully built working a like prototype<br>
    ● Successfully raised pre-seed fund<br>
    Even Though Aphrie did not work as planned due to financial crisis in Egypt back then, and as it was my
    first Startup experiment; I had to shut it down. However the knowledge and learnings from that journey
    was the most precious gain. It has also changed and shaped my thinking as a mature Engineer later on.
    `,
  },
  {
    name: "Youxel",
    logoUrl: "assets/career/youxel-logo.png",
    startDate: "MAY 2014",
    endDate: "MAY 2016",
    position: "Technical Team Lead",
    summary: `Youxel provides software solutions for our clients. First 6 months I was involved in service team, and later
    moved to lead a team working on a product called Tremvo, which later became adam.ai<br>
    <b>Responsibilities & Achievements:</b>
    ● Plan and coordinate tasks with our project manager<br>
    ● Implement advanced Engineering topics<br>
    ● Brainstorm with Engineers to improve our solution, and seek new possible technologies<br>
    ● Migrate old codebase from jQuery to Angular<br>
    ● Learn and apply Agile methodology<br>
    ● Managed customer service myself to understand our users better<br>
    `,
  },
  {
    name: "M.E.R.E",
    logoUrl: "assets/career/mdepc-logo.png",
    startDate: "APR 2010",
    endDate: "MAY 2014",
    position: "Software Engineer",
    summary: `
    <b>Responsibilities & Achievements:</b>
    ● IT & software support for various applications, mainly the plant core sppa-t3000 developed by
    Siemens<br>
    ● Integrate smart solutions that serve plant routines and frequent tasks<br>
    ● Software support for GE Speedtronic Mark VIe and ABB symphony harmony<br>
    `,
  },
];
