import fs from "fs";
import template from "bundle-text:./index.html";
import { templateLoopParser } from "../parser";

const answerTemplate = fs.readFileSync(__dirname + "/template.html", "utf8");
let page = 1;
let isLoadingMoreAnswers = false;
let loadMoreBtn;
let loadedAnswersCountCotainer;
let totalAnswersCountCotainer;
let answersCount = 0;

export const Answers = () => {
  setTimeout(() => {
    fetchTemplates();
    loadMoreBtn = document.querySelector("#load-more-answers");
    loadMoreBtn.onclick = loadMoreSOFAnswers;
    loadedAnswersCountCotainer = document.querySelector("#loaded-answers-count");
    totalAnswersCountCotainer = document.querySelector("#total-answers-count");
  }, 0);

  const loadMoreSOFAnswers = () => {
    if (!isLoadingMoreAnswers) {
      page++;
      fetchTemplates();
    }
  };

  const fetchTemplates = () => {
    isLoadingMoreAnswers = true;
    document.querySelector(".answers-loading").style.display = "flex";
    const answersContainer = document.querySelector(".answers-container");
    // answersContainer.innerHTML = "";
    fetch(
      `https://api.stackexchange.com/2.3/users/3025259/answers?pagesize=100&page=${page}&order=desc&sort=activity&site=stackoverflow&filter=!8qyGVhQpsLRfafW66vPQjPB0y`,
    )
      .then((response) => response.json())
      .then((response) => {
        const { items, has_more, page, page_size, total } = response;
        if (!has_more) {
          loadMoreBtn.style.display = "none";
        }
        const filteredanswers = items.map((answer) => {
          const { body, share_link, title } = answer;
          return { body, share_link, title };
        });
        answersCount += items.length;
        loadedAnswersCountCotainer.innerText = answersCount;
        totalAnswersCountCotainer.innerText = total;

        const answersContent = `<div class="answers-list">${templateLoopParser(filteredanswers, answerTemplate)}</div>`;
        const answers = new DOMParser().parseFromString(answersContent, "text/html");
        answers.querySelector(".answers-list").childNodes.forEach((child) => {
          answersContainer.prepend(child);
        });

        // remove loading
        document.querySelector(".answers-loading").style.display = "none";
        isLoadingMoreAnswers = false;
      });
  };

  return template;
};
