import { Sidenav } from "./components/side-nav";
import { Intro } from "./sections/intro";
import { About } from "./sections/about";
import { Skills } from "./sections/skills";
import { Articles } from "./sections/articles";
import { Projects } from "./sections/projects";
import { Answers } from "./sections/answers";
import { Contact } from "./sections/contact";

async function App() {
  // console.log(Sidenav);
  const template = document.createElement("template");
  template.innerHTML = `
    <div class="container">
    ${Sidenav()}
      <div class = "content">
      ${Intro()}
      ${About()}
      ${Skills()}
      ${Articles()}
      ${Projects()}
      ${Answers()}
      ${Contact()}
      </div>
    </div>
  `;
  // Return a new node from template
  return template.content.cloneNode(true);
}

export default App;
